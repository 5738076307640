import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 133,
    name: "Mitigating Truck Shadow Effects in Autonomous Vehicle Perception through Machine Learning algorithms",
    pageNumber: "1-8",
    authorData: [
      {
        name: "A. Sangeet Sahaya Jeyangela,",
        department: "Department of Automobile Engineering,",
        university: "Madras Institute of Technology, Chromepet, Chennai.",
      },
      {
        name: "Boopathi. D",
        designation: "Assistant Professor,",
        department: "Department of Automobile Engineering,",
        university: "SRM Institute of Science and Technology, Chennai.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.06.01",
    keywords:
      "Autonomous Vehicles, Machine Learning, Object Detection, Sensor Fusion, ROC-AUC, Navigation Safety, Model Comparison.",
    abstract:
      "Autonomous vehicles (AVs) must operate safely and effectively in complex environments. Accurate object detection and navigation are vital to AV performance, especially as vehicles face challenges like shadows, occlusions, and varying lighting conditions. Improving AV perception systems using machine learning (ML) models can enhance detection and classification accuracy, ultimately enabling safer navigation. This study evaluates the performance of five machine learning classifiers for AV object detection and navigation, considering metrics like accuracy, precision, recall, and area under the receiver operating characteristic (ROC-AUC). The study focuses on identifying the most effective ML model for accurate object classification and navigation under challenging conditions, thus optimizing AV safety. To achieve this, we propose a structured ML framework to improve AV perception accuracy. A comprehensive ML pipeline was developed, encompassing data preprocessing, feature extraction, model training, and performance evaluation. Preliminary results show that Random Forest achieves high accuracy, while SVM demonstrates superior precision, suggesting a hybrid model could combine the strengths of both.",
    published: "2024-01-20",
    source: "Vol 02. Issue. 06 Nov (2024)",
    cite: "A. Sangeet Sahaya Jeyangela, Boopathi. D, “Mitigating Truck Shadow Effects in Autonomous Vehicle Perception through Machine Learning algorithms”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 06, pp. 1–8, Nov. 2024. DOI: https://doi.org/10.59567/ktAML.V2.06.01",
  },
  {
    id: 134,
    name: "Investigating Cellular Memory Through Repeated Stimuli: A Case Study on Heat Shock Protein Expression",
    pageNumber: "09-13",
    authorData: [
      {
        name: "D. Sharmila,",
        designation: "Assistant Professor,",
        department: "Department of Industrial Biotechnology,",
        university: "BIHER, Chennai.",
      },
      {
        name: "F. Emerson Solomon",
        designation: "Professor,",
        department: "Department of Bio Medical Engineering,",
        university: "BIHER, Chennai.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.06.02",
    keywords:
      "Cellular memory, heat shock proteins, HSP70, stress response, gene expression, repeated stimuli, adaptation.",
    abstract:
      "Cellular memory is a phenomenon where cells retain information about past stimuli, enabling them to respond more effectively upon subsequent exposure. This study focuses on heat shock protein 70 (HSP70), a key player in stress responses, to explore the concept of cellular memory. Cells were subjected to two rounds of heat shock, with their gene expression levels analyzed to determine if a memory-like behavior exists. Using computational modeling, we observed faster and more robust upregulation of HSP70 during the second heat shock cycle. This finding suggests that cellular memory plays a critical role in stress adaptation, with implications for understanding biological resilience, developing stress-resilient cell lines, and exploring therapeutic interventions for diseases where stress responses are dysregulated.",
    published: "2024-11-20",
    source: "Vol 02. Issue. 06 Nov (2024)",
    cite: "D. Sharmila, F. Emerson Solomon, “Investigating Cellular Memory Through Repeated Stimuli: A Case Study on Heat Shock Protein Expression”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 06, pp. 09–13, Nov. 2024. DOI: https://doi.org/10.59567/ktAML.V2.06.02",
  },
  {
    id: 135,
    name: "Mapping Gene Expression and Transcription Inhibition to Cancer Treatment Cost Estimation",
    pageNumber: "14-20",
    authorData: [
      {
        name: "R. Priya,",
        designation: "Associate Professor,",
        department: "Department of Bio-Medical Engineering,",
        university:
          "Vels Institute of Science, Technology and Advanced Studies, Tamilnadu, India.",
      },
      {
        name: "Sundar Raj M",
        designation: "Professor,",
        university: "PPG Institute of Technology, Coimbatore, Tamilnadu",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.06.03",
    keywords:
      "Transcription Inhibition, Cancer Treatment Cost, Gene Expression, Machine Learning, Random Forest, Gradient Boosting, Budget Optimization.",
    abstract:
      "The escalating costs of cancer treatments present a significant challenge for healthcare systems globally. Traditional methods of estimating cancer treatment costs often fail to incorporate patient-specific biological factors. Recent advancements in predictive oncology have emphasized the need for machine learning techniques to predict treatment costs based on biological attributes. Transcription inhibition plays a pivotal role in             regulating gene expression and cancer cell proliferation, yet the relationship between transcription inhibition and treatment budgets remains under- explored. This study aims to address this gap by creating a machine learning (ML) framework that combines transcription inhibition dynamics with cancer treatment budgeting, with the goal of improving the accuracy of cost estimations. Our framework involves generating synthetic data representing transcription inhibition attributes, training multiple regression models, and evaluating them based on metrics like Mean Absolute Error (MAE), Mean Squared Error (MSE), and the R² score. The machine learning models applied include Linear Regression, Random Forest, Gradient Boosting, Support Vector Regressor, and Neural Network Regressor. Random Forest and Gradient Boosting models showed promising results, outperforming other models in budget prediction. This approach offers potential applications in precision oncology, with implications for optimizing resource allocation and supporting cost-effective clinical decision-making.",
    published: "2024-11-20",
    source: "Vol 02. Issue. 06 Nov (2024)",
    cite: "R. Priya, Sundar Raj M “Mapping Gene Expression and Transcription Inhibition to Cancer Treatment Cost Estimation”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 06, pp. 14-20, Nov. 2024. DOI: https://doi.org/10.59567/ktAML.V2.06.03",
  },
];

function ArchivedIssues10() {
  const id = 11;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 103,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
    {
      id: 104,
      imageName: "journal-cover-issue4.png",
      volumeNumber: "Vol. 01, Issue. 04, Sep (2023)",
    },
    {
      id: 105,
      imageName: "journal-cover-issue5.png",
      volumeNumber: "Vol. 01, Issue. 05, Nov (2023)",
    },
    {
      id: 106,
      imageName: "journal2-issue1.png",
      volumeNumber: "Vol.02, Issue 01, Jan(2024)",
    },
    {
      id: 107,
      imageName: "journal2-issue2.png",
      volumeNumber: "Vol.02, Issue. 02, Mar(2024)",
    },
    {
      id: 108,
      imageName: "journal2-issue3.png",
      volumeNumber: "Vol.02, Issue. 03, May(2024)",
    },
    {
      id: 109,
      imageName: "journal2-issue4.png",
      volumeNumber: "Vol.02, Issue. 04, July(2024)",
    },
    {
      id: 110,
      imageName: "journal2-issue5.png",
      volumeNumber: "Vol.02, Issue. 05, Sep(2024)",
    },
    {
      id: 111,
      imageName: "journal2-issue6.png",
      volumeNumber: "Vol.02, Issue. 06, Nov(2024)",
    },
  ];

  return (
    <>
      <Header idPath='true' />
      <Container>
        <Row className='pb-2'>
          <h3 className='section-heading'>
            {archiveData[id - 1].volumeNumber}
          </h3>
        </Row>
        <Row className='pb-4'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src={`${basePath}/images/journal2-issue6.png`}
                alt='Journal Thumbnail'
                className='journal-thumbnail'
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2024-11-20
            </p>
          </Col>
        </Row>
      </Container>
      <Container className='pt-3 pb-5'>
        <Row>
          <Col>
            <h2 className='sub-heading m-0'>Papers</h2>
            <hr className='heading-line' />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className='flex-style paper-info'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className='paper-author'
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className='flex-style py-2'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className='download-button'>
                    <Button
                      variant='secondary'
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=''
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className='flex-style'
                style={{ alignItems: "start" }}
              >
                <p key={i} className='page-number'>
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues10;
